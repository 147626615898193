import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Wafer level packaging inspection tools`}</h4>
    <p>{`These tools inspect parts of wafers that package fabricated chips (dies) before these wafers are "diced‚" (i.e., cut) into multiple chips.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      